import React from 'react'
import './App.css'
import { HomePage } from './pages'

const App = () => {
    return (
        <>
            <HomePage />
        </>
    )
}

export default App
